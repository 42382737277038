<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma
        And Its Impact On The Health Of Nigerians
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="../../assets/blog new/eyecarengonline_1628779252293258.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About
            Glaucoma And Its Impact On The Health Of Nigerians
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: July 19, 2021</p>
            </span>
          </div>
          <p class="">
            In this exclusive interview with Carriers of Life, Dr Rose Azuike, a
            renowned optometrist, currently, the principal optometrist and
            managing director of Kesona Eye Centre, Lagos, speaks extensively on
            glaucoma, its types and causes, and significantly, its impact on the
            wellbeing of Nigerians. She also discusses drugs used to treat
            glaucoma and how they are stored.
          </p>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              Ma, can we meet you?
            </p>
            <p class="">
              My name is Dr Rose Azuike. I am the managing director and
              principal optometrist at Kesona Eye Centre, Lagos.
            </p>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              So you are an optician?
            </p>
            <p class="">
              No, I am not an optician. I am an optometrist. Opticians are the
              Technicians that fix Eyeglasses using prescription gotten from an
              optometrist (primary eye care doctor) or ophthalmologists (medical
              doctors and eye surgeons) Pleased to meet you ma. We'd like to
              delve into the primary discussion of the day. What is glaucoma?
              Glaucoma is an eye disease that gradually damages the optic nerves
              at the back of your eye leading to a loss of vision.
            </p>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              What causes glaucoma?
            </p>
            <p class="">
              There are quite a number of causes, one common one is an increase
              in the pressure of the eye. Most times when people hear glaucoma
              what comes to their mind is that it is caused by an increase in
              eye pressure but that is not the only cause. Another cause is when
              the optic nerves at the back of the eye are very sensitive to
              pressure. So people can still have normal pressure and have
              glaucoma. Then people that tend to have conditions that reduce
              blood flow to the eye can also develop glaucoma. Generally,
              anything that can cause an increase in eye pressure that makes the
              optic nerve sensitive to pressure and also cut off or slow down
              blood flow to the eye can cause glaucoma.
            </p>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              What are the symptoms of glaucoma?
            </p>
            <p class="">
              The most common cause of glaucoma usually does not show symptoms.
              It doesn't show symptoms on time in fact by the time you start
              seeing symptoms you have already started losing your vision -
              peripheral vision to be specific. There is another type of
              glaucoma that is called angle-closure glaucoma, it comes with
              symptoms like eye pain and a decrease in vision.
            </p>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              How is glaucoma treated? <br />
              Can it be Cure?
            </p>
            <p class="">
              Glaucoma can not be cured. Glaucoma is generally treated with eye
              drops. Those whose eye drops are not enough, they undergo surgery.
              Treatment is different from cure. Treatment only stops the disease
              in its tracks and keeps it from causing more damage. For now, once
              you are diagnosed with glaucoma, treatment continues for life.
            </p>
            <figure class="pt-8">
              <img
                src="../../assets/blog new/image (5).jpg"
                alt=""
                width="100%"
                class="pb-4"
              />
            </figure>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              Who is at high risk of developing glaucoma?
            </p>
            <p class="">
              People at risk of developing glaucoma include people with a family
              history of glaucoma. If you have relatives in your family that
              have glaucoma then you are at high risk of developing it. Another
              set of people are those 40 years and above, those who have had eye
              surgery, those who are shortsighted, those who use medications for
              a prolonged period especially corticosteroids, and those who have
              diseases like hypertension, diabetes and any disease that can
              cause an increase in eye pressure.
            </p>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              We understand that certain glaucoma medications must be kept at
              certain temperatures because they are highly
              temperature-sensitive. What happens if these drugs are not stored
              at these temperatures? Does it have any negative impact on the
              person on which it is administered?
            </p>
            <p class="">
              I am not a pharmacist, that is not my area of speciality. But, my
              understanding about the storage of drugs is that if a drug is not
              stored at the recommended temperature, its main constituents will
              become denatured, and it is going to lose its potency. In the case
              of glaucoma, you do not want that because the drug will be
              ineffective when administered to a patient. Xalatan for instance
              is one of the drugs used to treat glaucoma. Xalatan requires very
              low temperatures for storage, hence it is stored in the fridge.
              Because of how temperature-sensitive it is, I do not store it in
              my eye centre because I can not guarantee electricity supply when
              I am not present. If I must prescribe Xalatan to a patient, I'll
              refer the patient to a pharmacist with a prescription.
            </p>
            <figure class="pt-8">
              <img
                src="../../assets/blog new/image (4).jpg"
                alt=""
                width="100%"
                class="pb-4"
                style="max-height: 28rem;object-fit: contain"
              />
            </figure>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              Where do you recommend patients get these drugs from?
            </p>
            <p class="">
              I'll advise people to, first of all, consider an eye care
              provider, then reputable pharmacies. I always advise patients to
              avoid buying drugs from unauthorized persons.
            </p>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              From a general perspective, seeing the condition of health care in
              Africa is in a bad state, do you think having proper storage in
              place for every drug produced will have a positive impact on the
              healthcare system?
            </p>
            <p class="">
              From a general perspective, I think private practitioners should
              handle health resources according to their level and according to
              the facilities they have. For instance, they are drugs I don't
              store in the eye centre, rather I write out a prescription and
              refer the patient to a reputable pharmacy. Another thing you can
              do is to avoid prescribing drugs that require complex storage
              conditions and opt for those that have less complex storage
              requirements. Patients can also talk to the eye care provider
              about his, or her, difficulty in preserving a certain drug, and
              request an alternative. Seeing the current state of healthcare in
              Nigerian, having every eye care centre own a fridge for the
              storage of drugs might not be feasible.
            </p>
            <figure class="pt-8">
              <img
                src="../../assets/blog new/image (3).jpg"
                alt=""
                width="100%"
                class="pb-4"
                style="max-height: 21rem;object-fit: contain;"
              />
            </figure>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              Basically, your advice is that healthcare providers ought to
              consider their capacity when it comes to offering health services?
            </p>
            <p class="">
              Yes. However, not only the capacity of the health facility but the
              patient also. You need to consider the ability of the patient to
              store prescribed drugs. For instance, in the case of eye drops
              that are mostly thermally reactive, you should know if the person
              has a fridge for storing the drug. If not, you should offer the
              patient an alternative if available.
            </p>
          </div>
          <div class="interview-text">
            <p class="font-weight-bold mb-1 orange-text">
              What are your closing words? Something you would love people to
              know about their health.
            </p>
            <p class="">
              Glaucoma is the most common cause of irreversible blindness in
              Nigerians. It is an eye condition we should take seriously, and if
              you have been diagnosed with glaucoma, you should get treatment
              from a reputable eye centre, and store your medications properly.
              If you have to use temperature-sensitive medication, you should
              consider getting cold storage equipment for use. Health facilities
              can consider getting very good and advanced cold storage equipment
              to store drugs that need specific temperatures like glaucoma.
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from '../../assets/images/baby vaccination.jpg';
import image5 from '../../assets/images/covid oon deep oce.jpg';
import image6 from '../../assets/images/covid19-vaccine-waste.jpg';
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    info: ['News', 'Health', 'Logistics'],
    rightSide: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content:
          'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link:
          '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content:
          'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link:
          '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    mySVG: require('../../assets/Logo/search.svg'),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Blog',
                item: 'https://gricd.com/blog',
              },
              {
                '@type': 'ListItem',
                position: 3,
                name:
                  ' INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About GlaucomaAnd Its Impact On The Health Of Nigerians',
                item:
                  'https://gricd.com/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
p span {
  text-decoration: underline;
  color: #28a84a;
}
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.orange-text {
  color: #eca31cf2;
  font-size: 1.2rem;
}
</style>
